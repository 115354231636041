/* styles.css */

/* CSS Variables */
:root {
  --primary-color: #4b8adc;
  --primary-color-light: #4b8adc91;
  --shadow-color: rgba(0, 103, 238, 0.253);
  --background-color: #f8f9fa;
  --border-color: #dee2e6;
  --text-primary: #212529;
  --text-secondary: #6c757d;
  --badge-language: #00bcd4;
  --badge-technology: #757575;
  --badge-year: #4b8adc;
  --badge-seasonal: #2196f3;
}

/* Base Styles */
body {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

/* Animated Background */
.animated-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: background 0.3s ease;
}

/* Layout */
.row-equal-height {
  display: flex;
  flex-wrap: wrap;
}

.custom-card {
  margin-bottom: 20px;
  display: flex;
  flex-grow: 1;
}

/* Card Styles */
.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: transform 0.2s ease;
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.card:hover {
  transform: scale(1.03);
}

.card-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: 1px solid var(--primary-color-light);
  border-radius: 5px;
  box-shadow: 0 4px 6px 0 var(--shadow-color);
}

.card-title {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--text-primary);
  margin-bottom: 1rem;
}

.compact-card-body {
  padding: 1.25rem;
}

/* Header Section */
.header-section {
  position: relative;
  z-index: 1;
  padding: 2.5rem 0 2rem;
  margin-bottom: 2rem;
  text-align: center;
  background: rgba(75, 138, 220, 0.03);
}

.profile-title {
  font-size: 2.5rem;
  font-weight: 500;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
  letter-spacing: -0.5px;
}

.profile-subtitle {
  font-size: 1.1rem;
  color: var(--text-secondary);
  font-weight: 400;
}

.animated-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: background 0.5s ease;
}

.card {
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(75, 138, 220, 0.15);
  box-shadow: 0 2px 4px rgba(75, 138, 220, 0.06);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(75, 138, 220, 0.1);
}
/* Bio Section */
.bio-text {
  font-size: 0.95rem;
  line-height: 1.5;
  margin-bottom: 0;
}

/* Skills Section */
.skill-section {
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}

.skill-section:last-child {
  border-bottom: none;
}

.filled-star {
  color: #f0c605;
}

.hollow-star {
  color: var(--text-secondary);
}

.interactive-element {
  cursor: pointer;
  color: var(--text-primary);
  text-decoration: none;
}

/* Badge Styles */
.badge {
  font-size: 0.8rem;
  font-weight: normal;
  padding: 0.4em 0.8em;
  border-radius: 4px;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
}

.badge.language-badge {
  background-color: var(--badge-language);
}

.badge.technology-badge {
  background-color: var(--badge-technology);
}

.badge.year-badge {
  background-color: var(--badge-year);
}

.badge.seasonal-badge {
  background-color: var(--badge-seasonal);
}

/* Link Styles */
.connect-link {
  color: var(--primary-color);
  text-decoration: none;
  margin-right: 1rem;
  display: inline-flex;
  align-items: center;
  transition: color 0.2s ease;
}

.connect-link:hover {
  color: #2a5ca3;
  text-decoration: none;
}

/* Utility Classes */
.text-muted {
  color: var(--text-secondary) !important;
}

.section-divider {
  height: 1px;
  background-color: var(--border-color);
  margin: 1rem 0;
}

/* Media Queries */
@media (max-width: 992px) {
  .container {
    max-width: 95%;
  }
  
  .card {
    margin-bottom: 1rem;
  }
  
  .compact-card-body {
    padding: 1rem;
  }
}

@media (max-width: 768px) {
  .header-section {
    padding: 1.5rem 0;
  }

  .profile-title {
    font-size: 2rem;
  }

  .profile-subtitle {
    font-size: 1rem;
  }

  .card-body {
    padding: 1rem;
  }
}